import React from "react";
import styled from "styled-components";
import buttonArrow from "../../assets/Icons/svgs/button-arrow.svg";
import { BREAKPOINTS } from "../../theme/breakpoints";

const Button = styled.button`
  display: flex;
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 64px;
  background: #9342e8;
  border: none;
  cursor: pointer;
  @media (max-width: ${BREAKPOINTS.md}px) {
    gap: 5px;
    padding: 10px 10px 10px 12px;
    border-radius: 46.299px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    gap: 5px;
    padding: 10px 10px 10px 12px;
    border-radius: 46.299px;
  }
`;
const Text = styled.p`
  color: var(--Neutral-0, #fff);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 16px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 16px;
  }
`;
const Icon = styled.img`
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 23.751px;
    height: 23.751px;
    flex-shrink: 0;
  }
`;
const EarlyAccessButton = () => {
  return (
    <Button>
      <Text>Get the Early Access</Text>
      <Icon src={buttonArrow} alt="" />
    </Button>
  );
};

export default EarlyAccessButton;
