import React from "react";
import styled from "styled-components";
import TitleBox from "./TitleBox";
// import bigIcon from "../../assets/Illustrations/svgs/big-controlfinance-icon.svg";
import gridLines from "../../assets/frame/gridLines.svg";
import CardBox from "./CardBox";
import { BREAKPOINTS } from "../../theme/breakpoints";
import gridLinesMobile from "../../assets/frame/gridlines-mobile.svg";

const MainDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 120px;
  gap: 64px;
  position: relative;
  padding-bottom: 170px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    padding-top: 87px;
    gap: 28px;
    padding-bottom: 100px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    padding-top: 65px;
    gap: 28px;
    padding-bottom: 65px;
  }
`;
const GridLines = styled.div`
  position: absolute;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    display: none;
  }
`;
const GridLine = styled.img`
  height: 700px;
  object-fit: cover;
  width: 900px;
  padding-bottom: 80px;
  display: flex;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    display: none;
  }
`;

const GridLinesMobileImage = styled.img`
  display: none;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    display: flex;
  }
`;

const Index = () => {
  return (
    <MainDiv>
      <GridLines>
        <GridLine src={gridLines} alt="" />
        <GridLinesMobileImage src={gridLinesMobile} alt="" />
      </GridLines>
      <TitleBox />
      <CardBox />
    </MainDiv>
  );
};

export default Index;
