import React from "react";
import styled from "styled-components";
import { BREAKPOINTS } from "../../theme/breakpoints";

const MainDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  gap: 16px;
  z-index: 11;
`;
const Text = styled.p`
  color: #666;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  font-family: "ClashGrotesk-Regular";
  @media (max-width: ${BREAKPOINTS.xs}px) {
    text-align: center;
    font-size: 16px;
    line-height: 22px; /* 137.5% */
    width: 70%;
  }
`;
const Heading2 = styled.h2`
  color: #200c32;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 50.958px; /* 106.162% */
  letter-spacing: -1.44px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 42px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 36px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    text-align: center;
    font-size: 32px;
    line-height: 38px; /* 118.75% */
    letter-spacing: -0.96px;
    width: 60%;
  }
`;
const TitleBox = () => {
  return (
    <MainDiv>
      <Heading2>One Unified Shopping Email</Heading2>
      <Text>
        Say goodbye to the hassle of multiple accounts and scattered emails.
      </Text>
    </MainDiv>
  );
};

export default TitleBox;
