import React from "react";
import styled from "styled-components";
import DataControlFrame from "../../assets/frame/Data-Control-frame.svg";
import HalfLock from "../../assets/Illustrations/svgs/half-lock.svg";
import { BREAKPOINTS } from "../../theme/breakpoints";

const MainDiv = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    padding: 10px;
  }
`;
const MainCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-radius: 13.403px;
  background: #200c32;
  overflow: hidden;
  height: 840px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 650px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    height: 620px;
  }
`;
const UpperBox = styled.div`
  height: 50%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
`;
const MainFrame = styled.img`
  width: 100%;
  position: absolute;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 200%;
  }
`;
const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  align-items: center;
  gap: 16px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    margin-top: 53px;
  }
`;
const TextContainer = styled.div`
  display: flex;
  text-align: center;
  gap: 10px;
`;
const Heading = styled.p`
  color: #fff;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 50.958px; /* 106.162% */
  letter-spacing: -1.44px;
  z-index: 99;
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 38px;
    line-height: 50.958px; /* 159.243% */
    letter-spacing: -0.96px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 32px;
    line-height: 50.958px; /* 159.243% */
    letter-spacing: -0.96px;
  }
`;
const GradientHeading = styled.p`
  background: linear-gradient(294deg, #eb8447 -7.46%, #bb84f3 34.54%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 50.958px;
  letter-spacing: -1.44px;
  z-index: 99;
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 38px;
    line-height: 50.958px; /* 159.243% */
    letter-spacing: -0.96px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 32px;
    line-height: 50.958px; /* 159.243% */
    letter-spacing: -0.96px;
  }
`;
const InfoText = styled.p`
  width: 820px;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 122.222% */
  z-index: 99;
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 18px;
    line-height: 22px; /* 137.5% */
    width: 90%;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 16px;
    line-height: 22px; /* 137.5% */
    width: 90%;
  }
`;
const HalfLockImg = styled.img`
  width: 522px;
  z-index: 99;
  position: absolute;
  bottom: 0;
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 375px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 329px;
  }
`;
const OverRectangle = styled.img`
  z-index: 99;
  position: absolute;
  bottom: 0;
  width: 524px;
  height: 162px;
  flex-shrink: 0;
  border-radius: 3px;
  /* border: 1px solid var(--Neutral-0, #fff); */
  background: #38007d;
  mix-blend-mode: soft-light;
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 375px;
    height: 115px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 329px;
    height: 100px;
  }
`;
const Index = () => {
  return (
    <MainDiv>
      <MainCard>
        <UpperBox>
          <MainFrame src={DataControlFrame} alt="" />
          <TextBox>
            <TextContainer>
              <Heading>Your Data</Heading>
              <GradientHeading>Your Control</GradientHeading>
            </TextContainer>
            <InfoText>
              Your data is securely encrypted and never shared. We prioritise
              your privacy and keep your information confidential. Enjoy
              personalized rewards based on your purchases, accessible only by
              you
            </InfoText>
          </TextBox>
        </UpperBox>
        <HalfLockImg src={HalfLock} alt="" />
        <OverRectangle />
      </MainCard>
    </MainDiv>
  );
};

export default Index;
