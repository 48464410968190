import React from "react";
import styled from "styled-components";
import bagIcon from "../../assets/Icons/svgs/shopping-bag.svg";
import coinIcon from "../../assets/Icons/svgs/coin-icon.svg";
import mailIcon from "../../assets/Icons/svgs/mail-icon.svg";
import firstCardBorders from "../../assets/Illustrations/svgs/first-card-borders.svg";
import { BREAKPOINTS } from "../../theme/breakpoints";
// import Marquee from "react-fast-marquee";

const MainDiv = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 261px;
  z-index: 11;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    height: 235px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 170px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    height: 126px;
    /* flex-shrink: 0; */
    gap: 8px;
  }
`;
const Line1 = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    gap: 12px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    gap: 5.87px;
  }
`;
const Line2 = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
  position: relative;
  right: 20px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    gap: 10px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    gap: 8.54px;
    right: auto;
  }
`;
const Line3 = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  /* align-items: center; */
  justify-content: end;
  padding-right: 93px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    gap: 14px;
    padding-right: 46.04px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    gap: 12px;
    padding-right: 46.04px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    gap: 8.54px;
    padding-right: 24.04px;
  }
`;

const MidCard = styled.div`
  display: flex;
  width: 172px;
  height: 71px;
  padding: 10px;
  align-items: center;
  gap: 8px;
  border-radius: 49.991px;
  border: 2px solid var(--Neutral-0, #fff);
  background: linear-gradient(117deg, #f5d59a 33.34%, #ed9968 242.2%);

  @media (max-width: ${BREAKPOINTS.lg}px) {
    height: 55px;
    gap: 0px;
    width: 140px;
    padding: 5px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 100px;
    height: 42px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 65.092px;
    padding: 3.668px;
    align-items: center;
    gap: 2px;
    height: 31px;
  }
`;
const MidLinesContainer = styled.div`
  width: 93px;
  /* height: 29.25px; */
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 82px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 55px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 26.553px;
    height: 10.729px;
    flex-shrink: 0;
  }
`;
const Icons = styled.img`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 45px;
    height: 45px;
    margin: 2px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 30px;
    margin: 2px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 23.494px;
    height: 23.494px;
  }
`;
const FirstGridLineImage = styled.img`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 75px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 50px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 27.553px;
    height: 10.729px;
    flex-shrink: 0;
    object-fit: fill;
  }
`;
const SecondCardBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 71px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    height: 36.309px;
    padding: 0px 7px;
    align-items: center;
    gap: 5.34px;
  }
`;
const SecondCard = styled.div`
  display: flex;
  width: 1412px;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 49.991px;
  border: 2px solid var(--Neutral-0, #fff);
  background: linear-gradient(117deg, #f5d59a 33.34%, #ed9968 242.2%);
  position: absolute;
  left: 0;

  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding: 4px;
    gap: 4px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 42px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    height: 36.309px;
    padding: 0px 7px;
    align-items: center;
    gap: 5.34px;
    border: 2.136px solid #fff;
  }
`;

const ThirdCard = styled.div`
  display: flex;
  width: 1412px;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 49.991px;
  border: 2px solid var(--Neutral-0, #fff);
  background: linear-gradient(117deg, #f5d59a 33.34%, #ed9968 242.2%);
  position: absolute;
  left: 0;
  margin-top: 8px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding: 4px;
    gap: 4px;
    margin-top: 7px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 42px;
    margin-top: 0;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    height: 36.309px;
    padding: 0px 7px;
    align-items: center;
    gap: 5.34px;
    margin-top: 0;
    border: 2.136px solid #fff;
  }
`;
const SecondLinesContainer = styled.div`
  /* height: 29.25px; */
  width: 1012px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 14.25px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    height: auto;
    gap: 10px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    gap: 8px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    height: auto;
    flex-shrink: 0;
    gap: 5.23px;
  }
`;
const WhiteStroke = styled.div`
  /* width: 791px; */
  height: 1.875px;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 8px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 1.2px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    height: 0.688px;
  }
`;
const SecondLine2Box = styled.div`
  display: flex;
  gap: 15px;
  padding-left: 6px;
`;
const SecondLine3Box = styled.div`
  display: flex;
  gap: 15px;
`;

const ThirdCardBox = styled.div`
  transform: scale(-1, 1);
  position: relative;
`;
const Heading1 = styled.p`
  color: #200c32;
  font-size: 88px;
  font-style: normal;
  font-weight: 500;
  line-height: 87px;
  letter-spacing: -2.64px;

  @media (max-width: ${BREAKPOINTS.xl}px) {
    font-size: 68px;
    line-height: 82px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 58px;
    line-height: 72px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 48px;
    line-height: 40px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 31.913px;
    letter-spacing: -1.08px;
  }
`;
const UpperHeroSection = () => {
  return (
    <MainDiv>
      <Line1>
        <Heading1>All Your</Heading1>
        <MidCard>
          <MidLinesContainer>
            {/* <Marquee direction="right" speed={20} style={{ paddingTop: 7 }}> */}
            <FirstGridLineImage src={firstCardBorders} alt="" />
            {/* </Marquee> */}
          </MidLinesContainer>

          <Icons src={bagIcon} alt="" />
        </MidCard>
        <Heading1>Shopping</Heading1>
      </Line1>
      <Line2>
        <Heading1>One Mailbox</Heading1>
        <SecondCardBox>
          <SecondCard>
            <Icons src={mailIcon} alt="" />
            <SecondLinesContainer>
              <WhiteStroke style={{ width: "791px" }} />
              <SecondLine2Box>
                <WhiteStroke style={{ width: "285px" }} />
                <WhiteStroke style={{ width: "180px" }} />
              </SecondLine2Box>
              <SecondLine3Box>
                <WhiteStroke style={{ width: "40px" }} />
                <WhiteStroke style={{ width: "95px" }} />
                <WhiteStroke
                  style={{ width: "208px", backgroundColor: "#ffffff92" }}
                />
                <WhiteStroke style={{ width: "310px" }} />
              </SecondLine3Box>
            </SecondLinesContainer>
          </SecondCard>
        </SecondCardBox>
      </Line2>
      <Line3>
        <ThirdCardBox>
          <ThirdCard>
            {/* <BlackIconBox> */}
            <Icons src={coinIcon} alt="" />
            {/* </BlackIconBox> */}
            <SecondLinesContainer>
              <WhiteStroke style={{ width: "791px" }} />
              <SecondLine2Box>
                <WhiteStroke style={{ width: "285px" }} />
                <WhiteStroke style={{ width: "180px" }} />
              </SecondLine2Box>
              <SecondLine3Box>
                <WhiteStroke style={{ width: "40px" }} />
                <WhiteStroke style={{ width: "95px" }} />
                <WhiteStroke
                  style={{ width: "208px", backgroundColor: "#ffffff92" }}
                />
                <WhiteStroke style={{ width: "310px" }} />
              </SecondLine3Box>
            </SecondLinesContainer>
          </ThirdCard>
        </ThirdCardBox>
        <Heading1>Real Rewards!</Heading1>
      </Line3>
    </MainDiv>
  );
};

export default UpperHeroSection;
