import React from "react";
import styled from "styled-components";
import logo from "../../../assets/logo/Logo.svg";
import { BREAKPOINTS } from "../../../theme/breakpoints";
import { Link } from "react-router-dom";

const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 56.78px;
`;
const LogoIcon = styled.img`
  width: 153.865px;
  height: 34.905px;
  flex-shrink: 0;
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 133.778px;
    height: 30.41px;
    flex-shrink: 0;
  }
`;
const index = () => {
  return (
    <Header>
      <Link to={"/"} style={{ textDecoration: "none" }}>
        <LogoIcon src={logo} alt="" />
      </Link>
    </Header>
  );
};

export default index;
